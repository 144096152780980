import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import RangeFieldSet from './RangeFieldSet';
import NumberField from './NumberField';
import RequiredNumberField from './RequiredNumberField';

type Props = {
  defaultEntryTimeNotBefore: string|null,
  defaultEntryTimeNotAfter: string|null,
  defaultEntryPriceNotBelow: string|null,
  defaultEntryPriceNotAbove: string|null,
  defaultMaxHoldingBusinessDays: string|null,
  defaultTakeProfitPct: string|null,
  defaultStopLossPct: string|null,
  defaultQuantity: string|null,
};

const NewStrategyForm: React.FC<Props> = ({
  defaultEntryTimeNotBefore,
  defaultEntryTimeNotAfter,
  defaultEntryPriceNotBelow,
  defaultEntryPriceNotAbove,
  defaultMaxHoldingBusinessDays,
  defaultTakeProfitPct,
  defaultStopLossPct,
  defaultQuantity,
}) => {
  const [entryTimeNotBefore, setEntryTimeNotBefore] = useState<string|null>(defaultEntryTimeNotBefore);
  const [entryTimeNotAfter, setEntryTimeNotAfter] = useState<string|null>(defaultEntryTimeNotAfter);
  const [isEntryTimeFieldSetOpen, setIsEntryTimeFieldSetOpen] = useState<boolean>(false);
  const [entryPriceNotBelow, setEntryPriceNotBelow] = useState<string|null>(defaultEntryPriceNotBelow);
  const [entryPriceNotAbove, setEntryPriceNotAbove] = useState<string|null>(defaultEntryPriceNotAbove);
  const [isEntryPriceFieldSetOpen, setIsEntryPriceFieldSetOpen] = useState<boolean>(false);
  const [maxHoldingBusinessDays, setMaxHoldingBusinessDays] = useState<string|null>(defaultMaxHoldingBusinessDays);
  const [isMaxHoldingBusinessDaysFieldOpen, setIsMaxHoldingBusinessDaysFieldOpen] = useState<boolean>(false);
  const [takeProfitPct, setTakeProfitPct] = useState<string|null>(defaultTakeProfitPct);
  const [stopLossPct, setStopLossPct] = useState<string|null>(defaultStopLossPct);
  const [quantity, setQuantity] = useState<string|null>(defaultQuantity);

  const applyMaxHoldingBusinessDays = (e) => {
    e.preventDefault();
    if (!maxHoldingBusinessDays) {
      setMaxHoldingBusinessDays(null);
    }
    setIsMaxHoldingBusinessDaysFieldOpen(false);
  }
  const resetMaxHoldingBusinessDays = (e) => {
    e.preventDefault();
    setMaxHoldingBusinessDays(null);
    setIsMaxHoldingBusinessDaysFieldOpen(false);
  }

  return(
    <>
      <RangeFieldSet
        label="エントリー時間"
        fieldType="time"
        minValue={entryTimeNotBefore}
        maxValue={entryTimeNotAfter}
        setMinValue={setEntryTimeNotBefore}
        setMaxValue={setEntryTimeNotAfter}
        isRangeValid={(minValue, maxValue) => {
          if (!minValue || !maxValue) {
            return false;
          }
          return (minValue < maxValue);
        }}
        isFieldSetOpen={isEntryTimeFieldSetOpen}
        setIsFieldSetOpen={setIsEntryTimeFieldSetOpen}
      />
      <hr className="my-2" />

      <RangeFieldSet
        label="エントリー価格帯"
        fieldType="number"
        minValue={entryPriceNotBelow}
        maxValue={entryPriceNotAbove}
        setMinValue={(newValue) => {
          setEntryPriceNotBelow(newValue?.replace(/[^0-9]/g, ''));
        }}
        setMaxValue={(newValue) => {
          setEntryPriceNotAbove(newValue?.replace(/[^0-9]/g, ''));
        }}
        isRangeValid={(minValue, maxValue) => {
          if (!minValue || !maxValue) {
            return false;
          }
          return (Number(minValue) < Number(maxValue));
        }}
        isFieldSetOpen={isEntryPriceFieldSetOpen}
        setIsFieldSetOpen={setIsEntryPriceFieldSetOpen}
      />
      <hr className="my-2" />

      <a onClick={() => setIsMaxHoldingBusinessDaysFieldOpen(true)}>
        <div className="d-flex justify-content-between align-items-center">
          <label className="trade-offcanvas__label">
            <i className={
              classnames("las", isMaxHoldingBusinessDaysFieldOpen ? "la-angle-down" : "la-angle-right")
            }></i>
            {" "}
            最大保有日数
          </label>
          {!isMaxHoldingBusinessDaysFieldOpen && <div className="text-end">
            {!!maxHoldingBusinessDays ? (
              `${maxHoldingBusinessDays}営業日後の14:00に自動決済`
            ) : (
              "指定しない"
            )}
          </div>}
        </div>
      </a>

      {isMaxHoldingBusinessDaysFieldOpen && <>
        <div className="d-flex">
          <div className="input-group">
            <input
              type="number"
              className="form-control trade-offcanvas__form-control"
              value={maxHoldingBusinessDays ?? ""}
              onChange={(e) => setMaxHoldingBusinessDays(e.target.value?.replace(/[^0-9]/g, ''))}
            />
            <span className="input-group-text trade-offcanvas__input-group-text">営業日後の14:00に自動決済</span>
          </div>
          <div className="mx-2">
            <button
              type="button"
              className="btn btn-outline-primary trade-offcanvas__square-button"
              onClick={applyMaxHoldingBusinessDays}
            >
              <i className="las la-check"></i>
            </button>
          </div>
          <div className="text-danger d-flex align-items-center">
            <a onClick={resetMaxHoldingBusinessDays}>
              <i className="las la-times"></i>
            </a>
          </div>
        </div>
        <p className="trade-offcanvas__help-text">
          例えば4月1日月曜日にエントリーし、2営業日後に決済する設定にした場合、4月3日の14:00に自動決済されます
        </p>
      </>}
      <hr className="my-2" />

      <div className="row">
        <div className="col-6 pe-1">
          <RequiredNumberField
            label="利益確定"
            appendedText="%"
            value={takeProfitPct}
            setValue={(newValue) => {
              setTakeProfitPct(newValue?.replace(/[^0-9]/g, ''));
            }}
            isValueValid={(value) => {
              if (!value) {
                return false;
              }
              return (Number(value) > 0);
            }}
          />
        </div>
        <div className="col-6 ps-1">
          <RequiredNumberField
            label="ストップロス"
            appendedText="%"
            value={stopLossPct}
            setValue={(newValue) => {
              setStopLossPct(newValue?.replace(/[^0-9]/g, ''));
            }}
            isValueValid={(value) => {
              if (!value) {
                return false;
              }
              return (Number(value) > 0);
            }}
          />
        </div>
      </div>
      <p className="trade-offcanvas__help-text">
        含み益、含み損が一定の割合に達した場合に、成行決済を行います
      </p>
      <hr className="my-2" />

      <div className="row">
        <div className="col-6 pe-1">
          <RequiredNumberField
            label="売買株数"
            appendedText="株買い"
            value={stopLossPct}
            setValue={(newValue) => {
              setStopLossPct(newValue?.replace(/[^0-9]/g, ''));
            }}
            isValueValid={(value) => {
              if (!value) {
                return false;
              }
              return (Number(value) > 0);
            }}
          />
        </div>
        <div className="col-6 ps-1">
          <label className="trade-offcanvas__label">
            概算売買代金
          </label>
        </div>
      </div>

      <div className="text-center mt-2">
        <button type="submit" className="btn btn-primary trade-offcanvas__square-button bg-cyan">
          自動売買を開始
        </button>
        <p className="trade-offcanvas__help-text">
          エントリー時間、価格帯によっては、すぐさまエントリーされる場合があります
        </p>
      </div>

    </>
  );
};

export default NewStrategyForm;