import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';

type Props = {
  label: string,
  appendedText: string,
  value: string|null,
  setValue: (value:string|null) => void,
  isValueValid: (value:string|null) => boolean,
};

const RequiredNumberField: React.FC<Props> = ({
  label,
  appendedText,
  value,
  setValue,
  isValueValid,
}) => {
  const [hasValueError, setHasValueError] = useState<boolean>(false);

  return(
    <>
      <label className="trade-offcanvas__label">
        {label}
      </label>

      <div className="d-flex">
        <div className="input-group trade-offcanvas__input-group">
          <input
            type="number"
            value={value ?? ""}
            onChange={(e) => setValue(e.target.value)}
            className={classnames(
              "form-control trade-offcanvas__form-control",
              hasValueError && "is-invalid",
            )}
          />
          <span className="input-group-text trade-offcanvas__input-group-text">{appendedText}</span>
        </div>
      </div>
    </>
  );

};

export default RequiredNumberField;