import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';

type Props = {
  label: string,
  unitText: string,
  appendedText: string,
  value: string|null,
  setValue: (value:string|null) => void,
  isValueValid: (value:string|null) => boolean,
  isFieldOpen: boolean,
  setIsFieldOpen: (value:boolean) => void,
};

const NumberField: React.FC<Props> = ({
  label,
  unitText,
  appendedText,
  value,
  setValue,
  isValueValid,
  isFieldOpen,
  setIsFieldOpen,
}) => {
  const [hasValueError, setHasValueError] = useState<boolean>(false);

  const applyValue = (e) => {
    e.preventDefault();
    if (!value) {
      setValue(null);
    } else if (!isValueValid(value)) {
      setHasValueError(true);
      return;
    }
    setIsFieldOpen(false);
  }

  const resetValue = (e) => {
    e.preventDefault();
    setValue(null);
    setIsFieldOpen(false);
  }

  return(
    <>
      <a onClick={() => setIsFieldOpen(true)}>
        <div className="d-flex justify-content-between align-items-center">
          <label className="trade-offcanvas__label">
            <i className={
              classnames("las", isFieldOpen ? "la-angle-down" : "la-angle-right")
            }></i>
            {" "}
            {label}
          </label>
          {!isFieldOpen && <div className="text-end">
            {!!value ? (
              `${value}${unitText}`
            ) : (
              "なし"
            )}
          </div>}
        </div>
      </a>

      {isFieldOpen && <div className="d-flex">
        <div className="input-group trade-offcanvas__input-group">
          <input
            type="number"
            value={value ?? ""}
            onChange={(e) => setValue(e.target.value)}
            className={classnames(
              "form-control trade-offcanvas__form-control",
              hasValueError && "is-invalid",
            )}
          />
          <span className="input-group-text trade-offcanvas__input-group-text">{appendedText}</span>
        </div>
        <div className="mx-2">
          <button
            type="button"
            className="btn btn-outline-primary trade-offcanvas__square-button"
            onClick={applyValue}
          >
            <i className="las la-check"></i>
          </button>
        </div>
        <div className="text-danger d-flex align-items-center">
          <a onClick={resetValue}>
            <i className="las la-times"></i>
          </a>
        </div>
      </div>}
    </>
  );

};

export default NumberField;